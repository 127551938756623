<template>
    <div class="c3-shop-list">
        <div class="c3-shop-list__title">
            <div>{{title}}</div>
            <div @click="create">
                <c3-icon-plus-circle primaryColor="quinary"/>
            </div>
        </div>
        <template v-for="item in newItems">
            <c3-list-item :item="item.item" :uuid="item.uuid" :key="item.uuid" :editMode="true" @created="removeNewItem" @canceled="removeNewItem"/>
        </template>
        <template v-for="item in filteredItems">
            <c3-list-item :item="item" :key="item.id"/>
        </template>
        <template v-if="0 === newItems.length && 0 === filteredItems.length">
            <div class="c3-shop-list__no-entries">
                {{ $t('label.noEntries') }}
            </div>
        </template>
    </div>
</template>
<script>
    import {Crypto} from '@/utility/crypto';
    import {ShopFactory} from '@/domain/factory/shop';
    import C3ListItem from '@/components/content/shopManagement/list/Item';

    export default {
        components: {
            C3ListItem
        },
        props: {
            title: {
                type: String
            },
            items: {
                type: Array
            }
        },
        data() {
            return {
                newItems: [],
            }
        },
        computed: {
            filteredItems() {
                let items = [...this.items];
                items.sort((a, b) => b.created.valueOf() - a.created.valueOf());
                return items;
            }
        },
        methods: {
            create() {
                this.newItems.unshift({
                    uuid: Crypto.generateUuid(),
                    item: ShopFactory.create()
                });
            },
            removeNewItem(uuid) {
                let index = this.newItems.findIndex(item => item.uuid === uuid);
                if (-1 < index) {
                    this.$delete(this.newItems, index);
                }
            }
        }
    }
</script>
<style lang="scss">
    $gutter: 30px;

    .c3-shop-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -1*$gutter;
        margin-left: -1*$gutter;
    }

    .c3-shop-list__title {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: $gutter;
        margin-left: $gutter;
        margin-bottom: -20px;

        @include openSansBold();
        font-size: 18px;

        .c3-icon-wrapper {
            display: block;
            height: 24px;
            margin-left: 10px;
        }
    }

    .c3-shop-list__no-entries {
        @include openSansSemiBold();
        margin-top: $gutter;
        margin-left: $gutter;
    }
</style>
