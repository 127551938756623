<template>
    <div class="c3-main">
        <div class="c3-page-content c3-page-content-padding">
            <c3-shop-management-list title="Shops" :items="items"/>
            <div class="c3-desktop-only-notice">
                {{ $t('error.desktopDeviceError') }}
            </div>
        </div>
    </div>
</template>
<script>
    import C3ShopManagementList from '@/components/content/shopManagement/list/Index';

    export default {
        components: {
            C3ShopManagementList,
        },
        computed: {
            items() {
                return Object.values(this.$store.state.userShop.shops.items).map(item => {
                    return item;
                })
            }
        },
        methods: {
            determineTypeLabel(type) {
                switch (type) {
                    case 'email':
                        return 'E-Mail';
                }

                return type;
            }
        },
        async created() {
            this.$store.commit('view/showLoading');
            try {
                await this.$store.dispatch('userShop/loadShop');
            } catch (e) {
                this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                console.error(e);
            }
            this.$store.commit('view/hideLoading');

        }
    }
</script>
<style lang="scss">
    .c3-main {
        background-color: $nonary-color;
    }

    .c3-desktop-only-notice {
        display: none;
    }

    @media(max-width: 1023px) {
        .c3-shop-list {
            display: none;
        }

        .c3-desktop-only-notice {
            display: block;
            @include openSansSemiBold();
            font-size: 16px;
        }
    }
</style>
