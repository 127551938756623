<template>
    <div class="c3-shop-item" :class="{'c3-shop-item--edit-mode':isEditModeActive}" ref="item">
        <template v-if="!isEditModeActive">
            <div class="c3-shop-item__name">{{item.name}}</div>
            <div class="c3-shop-item__action-edit" @click="activateEditMode">
                <c3-icon-edit primaryColor="quinary"/>
            </div>
            <div class="c3-shop-item__action-remove" @click="remove">
                <c3-icon-trash-alt primaryColor="tertiary"/>
            </div>

            <div v-for="contact in item.shopContacts" class="c3-shop-item__contact">
                <div class="c3-shop-item__contact-title">{{ $t('label.contact') }}</div>
                <div class="c3-shop-item__contact-body">
                    <div class="c3-shop-item__contact-value">{{contact.value}}</div>
                </div>
            </div>

            <div v-if="item.id" class="c3-shop-item__qr-code">
                <strong>{{ $t('label.qrCode') }}</strong>
                <div class="c3-shop-item__qr-code-action">
                    <c3-button :label="$t('label.download')" @click="qrCodeDownload"/>
                    <c3-button :label="$t('label.sendToContact')" @click="qrCodeSend"/>
                </div>
            </div>
        </template>
        <template v-else>
            <c3-form @submit="submit" padding="none">
                <c3-form-input v-model="editItem.name" :validator="$v.editItem.name" :label="$t('customer.name')"/>
                <c3-form-input v-model="editItem.email" :validator="$v.editItem.email" :label="$t('shop.email')"/>

                <div class="c3-shop-item__form-buttons">
                    <c3-button :label="$t('label.cancel')" styling="2" @click="cancel"/>
                    <c3-form-submit :label="$t('label.save')" styling="action" :isLoading="isEditItemLoading"/>
                </div>
            </c3-form>
        </template>
    </div>
</template>
<script>
    import {HTTP} from '@/domain/api/http';
    import {ShopFactory} from '@/domain/factory/shop';
    import C3Button from '@/components/form/Button';
    import C3IconTrashAlt from '@/components/icon/TrashAlt';


    export default {
        components: {
            C3Button,
            C3IconTrashAlt
        },
        props: {
            uuid: {
                type: String
            },
            item: {
                type: Object
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isEditModeActive: false,
                editItem: {
                    name: '',
                    email: ''
                },
                isEditItemLoading: false
            }
        },
        computed: {},
        methods: {
            activateEditMode() {
                let email = '';
                if (Array.isArray(this.item.shopContacts) && this.item.shopContacts.length) {
                    email = this.item.shopContacts[0].value;
                }
                this.editItem = {name: this.item.name, email: email};
                this.isEditModeActive = true;
            },
            cancel() {
                this.isEditModeActive = false;
                this.$emit('canceled', this.uuid);
            },
            async submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                let item = {
                    name: this.editItem.name.trim(),
                    shopContacts: [
                        {
                            type: "email",
                            "value": this.editItem.email.trim()
                        }
                    ]
                };

                this.isEditItemLoading = true;
                try {
                    if (this.item.id) {
                        item.id = this.item.id;
                        await this.$store.dispatch('userShop/updateShop', item);
                        this.$emit('updated', this.uuid);
                    } else {
                        await this.$store.dispatch('userShop/createShop', item);
                        this.$emit('created', this.uuid);
                    }
                } catch (e) {
                    console.error(e);
                }

                this.isEditItemLoading = false;
                this.isEditModeActive = false;
            },
            remove() {
                this.$c3.confirmYesNo(
                    'confirm.shop.title',
                    this.$t('confirm.shop.message', { name: this.item.name }),
                    async () => {
                        try {
                            this.$store.commit('view/showLoading');
                            await this.$store.dispatch('userShop/deleteShop', this.item);
                        } catch (e) {
                            console.error(e);
                            this.$c3.errorAlert('', 'error.shopDeleteError');
                        }
                        this.$store.commit('view/hideLoading');
                    }
                )
            },
            async qrCodeDownload() {
                this.$store.commit('view/showLoading');
                let response = await HTTP.get(API_BASE_URL + `/user/${this.currentUser.id}/shop/${this.item.id}/qr-code`, {responseType: 'blob'});
                let url = window.URL.createObjectURL(new Blob([response.data]));

                let hiddenElement = document.createElement('a');
                hiddenElement.href = url;
                hiddenElement.style.position = 'absolute';
                hiddenElement.style.left = '99999px';
                hiddenElement.target = '_blank';
                hiddenElement.download = 'QR-Code.pdf';
                console.debug(this.$refs.item);
                this.$refs.item.appendChild(hiddenElement);
                hiddenElement.click();
                this.$store.commit('view/hideLoading');
            },
            async qrCodeSend() {
                this.$store.commit('view/showLoading');
                await HTTP.post(API_BASE_URL + `/user/${this.currentUser.id}/shop/${this.item.id}/send-qr-code-mail`);
                this.$c3.successAlert('', 'success.emailSent');
                this.$store.commit('view/hideLoading');
            }
        },
        validations: {
            editItem: ShopFactory.createValidation()
        },
        created() {
            if (this.editMode) {
                this.activateEditMode();
            }
        }
    }
</script>
<style lang="scss" scoped>
    $gutter: 30px;

    .c3-shop-item {
        display: block;
        width: calc(100% - #{$gutter});
        min-height: 150px;

        position: relative;

        margin-top: $gutter;
        margin-left: $gutter;

        padding: 15px;

        background-color: $white-color;
        @include boxShadow();

        @media(min-width: 768px) {
            width: calc(50% - #{$gutter});
        }

        @media(min-width: 1200px) {
            width: calc(33.333% - #{$gutter});
        }
    }

    .c3-shop-item__name {
        @include openSansBold();
        font-size: 18px;
    }

    .c3-shop-item__action-edit {
        display: block;
        height: 24px;

        position: absolute;
        top: 15px;
        right: 54px;
    }

    .c3-shop-item__action-remove {
        display: block;
        height: 24px;

        position: absolute;
        top: 15px;
        right: 15px;
    }

    .c3-shop-item__contact {
        margin-top: 15px;
    }

    .c3-shop-item__contact-title {
        margin-bottom: 5px;
        @include openSansSemiBold();
    }

    .c3-shop-item__contact-body {
        display: flex;
    }

    .c3-shop-item__contact-type {
        padding-right: 5px;
        @include openSansSemiBold();
    }

    .c3-shop-item__qr-code {
        margin-top: 15px;
    }

    .c3-shop-item__qr-code-action {
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;
        margin-left: -15px;

        .c3-button {
            max-width: 180px;
            margin-left: 15px;
        }
    }

    // Edit mode
    .c3-shop-item--edit-mode {
        background-color: $secondary-color;

        & /deep/ .c3-form {
            max-width: none;
        }
    }

    .c3-shop-item__form-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        .c3-button--style-2 {
            width: 150px;
            margin-right: 15px;
        }

        .c3-form-field-group-submit {
            width: 150px;
            margin-top: 0;
        }
    }
</style>
