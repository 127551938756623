import {required, email, minLength, maxLength} from 'vuelidate/lib/validators'

export const ShopFactory = {
    create: (fields) => {
        let shop = {
            name: '',
            shopContacts: [
                {
                    type: 'email',
                    value: ''
                }
            ]
        };

        if (Array.isArray(fields) && fields.length) {
            let reducedShop = {};
            fields.forEach(field => {
                reducedShop[field] = shop[field];
            });
            return reducedShop;
        }

        return shop;
    },
    createValidation: () => {
        return {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(40)
            },
            email: {
                required,
                email,
                minLength: minLength(4),
                maxLength: maxLength(40)
            }
        };
    }
};
